<template>
  <div id="app">
    <v-app>
      <v-main>
        <!-- Menu principal -->
        <MenuPrincipal />
        <!-- Router -->
        <router-view></router-view>
        <!-- Rodape -->
        <RodapePrincipal /> </v-main
    ></v-app>
  </div>
</template>

<script>
// Importando os componentes
import MenuPrincipal from "./components/Menus/MenuPrincipal.vue";
import RodapePrincipal from "./components/Rodape/RodapePrincipal.vue";

export default {
  name: "App",
  components: { MenuPrincipal, RodapePrincipal },
  data: () => ({}),
  watch: {
    $route(to) {
      document.title = to.meta.title || "Parque das Flores";
    },
  },
};
</script>

<style>
/* RETIRANDO O SCROLL PARA OS LADOS DA PAGINA */
div.wrap {
  height: 100%;
  overflow: hidden;
}
body {
  overflow: hidden;
}
/* Importando as fonts */
@font-face {
  font-family: "Montserrat-Regular";
  src: local("Montserrat-Regular"),
    url(./fonts/Montserrat/Montserrat-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "Montserrat-Bold";
  src: local("Montserrat-Bold"),
    url(./fonts/Montserrat/Montserrat-Bold.ttf) format("truetype");
}
</style>