<template>
  <v-footer dark padless>
    <v-card color="#7092BF" flat tile class="text-center" style="width: 100%">
      <v-card-text class="pb-10">
        <v-btn
          color="white"
          class="mx-4"
          icon
          href="https://www.facebook.com/parquedasflores"
          target="_blank"
        >
          <v-icon x-large size="30px"> mdi-facebook </v-icon>
        </v-btn>
        <v-btn
          color="white"
          class="mx-4"
          icon
          href="https://www.instagram.com/parque_dasflores/"
          target="_blank"
        >
          <v-icon x-large size="30px"> mdi-instagram </v-icon>
        </v-btn>
        <v-btn
          color="white"
          class="mx-4"
          icon
          href="https://twitter.com/PCrematorio"
          target="_blank"
        >
          <v-icon x-large size="30px"> mdi-twitter </v-icon>
        </v-btn>
        <v-btn color="white" class="mx-4" icon href="#" target="_blank">
          <v-icon x-large size="30px"> mdi-linkedin </v-icon>
        </v-btn>
        <v-btn
          color="white"
          class="mx-4"
          icon
          href="https://www.youtube.com/channel/UC_8kUAnE_M1-rQJmkfJEdbQ/featured"
          target="_blank"
        >
          <v-icon x-large size="30px"> mdi-youtube </v-icon>
        </v-btn>
        <v-btn
          color="white"
          class="mx-4"
          icon
          href="https://br.pinterest.com/parquedasfloressjc/"
          target="_blank"
        >
          <v-icon x-large size="30px"> mdi-pinterest </v-icon>
        </v-btn>
      </v-card-text>
      <v-row>
        <v-col
          ><v-card-text class="pt-2" style="font-size: 18px; color: black">
            <strong>SERVIÇOS</strong>
          </v-card-text>
          <v-btn
            color="white"
            href=""
            target="_blank"
            text
            style="font-size: 14px"
          >
            <v-icon left>mdi-human-male-female-child</v-icon>
            Planos de Assitência <br />
            Funeral Familiar
          </v-btn>
          <br />
          <v-btn
            color="white"
            href=""
            target="_blank"
            text
            style="font-size: 14px"
          >
            <v-icon left>mdi-grave-stone</v-icon>
            Aquisição de Jazigo
          </v-btn>
          <br />
          <v-btn
            color="white"
            href=""
            target="_blank"
            text
            style="font-size: 14px"
          >
            <v-icon left>mdi-fireplace</v-icon>
            Aquisição de Cremação
          </v-btn>
          <br />
          <v-btn
            color="white"
            href=""
            target="_blank"
            text
            style="font-size: 14px"
          >
            <v-icon left>mdi-flower-outline</v-icon>
            Floricultura
          </v-btn>
          <br />
        </v-col>
        <v-col
          ><v-card-text class="pt-2" style="font-size: 18px; color: black">
            <strong>SOBRE O PARQUE DAS FLORES</strong>
          </v-card-text>
          <v-btn
            color="white"
            href=""
            target="_blank"
            text
            style="font-size: 14px"
          >
            <v-icon left>mdi-home-variant</v-icon>
            O PARQUE DAS FLORES
          </v-btn>
          <br />
          <v-btn
            color="white"
            href=""
            target="_blank"
            text
            style="font-size: 14px"
          >
            <v-icon left>mdi-home-variant</v-icon>
            Blog
          </v-btn>
          <br />
          <v-btn
            color="white"
            href=""
            target="_blank"
            text
            style="font-size: 14px"
          >
            <v-icon left>mdi-home-variant</v-icon>
            Propósito
          </v-btn>
          <br />
          <v-btn
            color="white"
            href=""
            target="_blank"
            text
            style="font-size: 14px"
          >
            <v-icon left>mdi-home-variant</v-icon>
            Por que o Parque das Flores?
          </v-btn>
        </v-col>
        <v-col>
          <v-row class="pt-3 justify-center">
            <v-card-text class="pt-2" style="font-size: 18px; color: black">
              <strong>CONTATO</strong>
            </v-card-text>
            <v-btn
              color="white"
              href="tel:+551239416599"
              target="_blank"
              text
              style="font-size: 14px"
            >
              <v-icon left>mdi-phone</v-icon>
              (12) 3941-6599
            </v-btn>
            <br />
            <v-btn
              color="white"
              href="mailto:atendimento@parquedasflores.com.br"
              target="_blank"
              text
              style="font-size: 14px"
            >
              <v-icon left>mdi-email</v-icon>
              atendimento@parquedasflores.com.br
            </v-btn>
          </v-row>
          <v-row class="pt-3 pb-8 justify-center">
            <v-card-text class="pt-2" style="font-size: 18px; color: black">
              <strong>ENDEREÇO</strong>
            </v-card-text>
            <v-btn
              color="white"
              href="https://g.page/parquedasflores?share"
              target="_blank"
              text
              style="font-size: 14px"
            >
              <v-icon left>mdi-map-marker</v-icon>
              Travessa do Capitingal, 114 <br />
              - Res. Gazzo, São José dos Campos - <br />
              SP, 12236-843
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-card-text style="background-color: grey" class="white--text">
        © Copyright
        {{ new Date().getFullYear() }} — Cemitério e Crematório Parque das
        Flores - Todos os direitos reservados
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
export default {
  name: "RodapePrincipal",
};
</script>