<template>
  <div>
    <div>
      <!-- MENU DESKTOP -->
      <v-app-bar hide-on-scroll app color="white" dark height="80px">
        <v-app-bar-nav-icon
          color="#31460D"
          @click="drawer = true"
          class="d-flex d-sm-none"
          style="margin-right: 10px"
        ></v-app-bar-nav-icon>
        <v-spacer></v-spacer>
        <!-- LOGO DESKTOP -->
        <a href="/">
          <div class="d-flex">
            <v-img
              alt="Logo Parque das Flores"
              class="shrink mt-1 hidden-sm-and-down"
              contain
              src="@/assets/LogosParqueDasFlores/logo_horizontal.png"
              width="280"
              transition="scale-transition"
            />
          </div>
        </a>
        <!-- LOGO MOBILE -->
        <a href="/">
          <div class="d-flex">
            <v-img
              alt="Logo Parque das Flores"
              class="shrink mt-1 d-sm-none"
              contain
              src="@/assets/LogosParqueDasFlores/logo_horizontal.png"
              transition="scale-transition"
              width="220"
            />
          </div>
        </a>
        <v-spacer></v-spacer>
        <div class="d-sm-none pl-4">
          <v-btn small color="#f34f51" href="tel:+551239416599">
            <v-icon>  mdi-phone-in-talk </v-icon>
          </v-btn>
        </div>
        <v-spacer></v-spacer>
        <template>
          <div align-with-title class="d-none d-sm-flex">
            <div class="text-center">
              <v-btn href="/#" color="#31460D" text dark large>
                <v-icon left> mdi-home </v-icon> Home
              </v-btn>
            </div>
            <div class="text-center">
              <v-btn href="/#" color="#31460D" text dark large>
                <v-icon left> mdi-home-city </v-icon>
                A empresa
              </v-btn>
            </div>
            <div class="text-center">
              <v-btn href="/#" color="#31460D" text dark large>
                <v-icon left> mdi-account-group </v-icon> Planos
              </v-btn>
            </div>
            <div class="text-center">
              <v-btn href="/#" color="#31460D" text dark large>
                <v-icon left> mdi-card-account-details </v-icon>
                Carteirinha
              </v-btn>
            </div>
            <div class="text-center">
              <v-btn href="/#" color="#31460D" text dark large>
                <v-icon left> mdi-file-document-edit </v-icon>
                Autorização Online
              </v-btn>
            </div>
            <div class="text-center">
              <v-btn href="/#" color="#31460D" text dark large>
                <v-icon left> mdi-phone-in-talk </v-icon> Contato
              </v-btn>
            </div>
          </div>
        </template>
      </v-app-bar>
    </div>
    <!-- Menu mobile -->
    <div>
      <v-navigation-drawer
        color="white"
        v-model="drawer"
        app
        temporary
        width="100%"
      >
        <v-list nav dense>
          <div v-for="(link, i) in links" :key="i">
            <v-list-item
              v-if="!link.subLinks"
              :to="link.to"
              class="v-list-item"
            >
              <v-list-item-icon>
                <v-icon>{{ link.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title v-text="link.text" />
            </v-list-item>
            <v-list-group
              v-else
              :key="link.text"
              no-action
              :prepend-icon="link.icon"
              :value="false"
            >
              <template v-slot:activator>
                <v-list-item-title>{{ link.text }}</v-list-item-title>
              </template>
              <v-list-item
                v-for="sublink in link.subLinks"
                :href="sublink.to"
                :key="sublink.text"
              >
                <v-list-item-icon>
                  <v-icon>{{ sublink.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{ sublink.text }}</v-list-item-title>
              </v-list-item>
            </v-list-group>
          </div>
          <div
            style="
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              margin-top: 20px;
            "
          >
            <v-btn @click="drawer = false">
              <v-icon left> mdi-close-circle </v-icon>
              Fechar
            </v-btn>
          </div>
        </v-list>
      </v-navigation-drawer>
    </div>
  </div>
</template>

<script>
export default {
  name: "MenuPrincipal",
  data: () => ({
    // MENU MOBILE
    drawer: false,
    links: [
      {
        text: "Home",
        to: "/#",
        icon: "mdi-home",
      },
      {
        text: "A Empresa",
        to: "/#",
        icon: "mdi-home-city",
      },
      {
        text: "Planos",
        to: "/#",
        icon: "mdi-account-group",
      },
      {
        text: "Carteirinha",
        to: "/#",
        icon: "mdi-card-account-details",
      },
      {
        text: "Autorização Online",
        to: "/#",
        icon: "mdi-file-document-edit",
      },
      {
        text: "Contato",
        to: "/#",
        icon: "mdi-phone-in-talk",
      },
    ],
  }),
};
</script>

<style scoped>
</style>